import request from "../request.js";

//分页查询作品
export function listByPage(params) {
  return request({
    url: "entry/listByPage",
    method: "GET",
    params,
  });
}

// /entry/getById or /award/getEntryById
// 按id查看单个作品详情
export function getById(params, isAward) {
  return request({
    url: isAward?"award/getEntryById":"entry/getById",
    method: "GET",
    params,
  });
}
///entry/markView
export function markView(params) {
  return request({
    url: "entry/markView",
    method: "POST",
    params,
  });
}
// 增加浏览次数
export function increaseView(params) {
  return request({
    url: "entry/increaseView",
    method: "POST",
    params,
  });
}

export function listByPageSub(params) {
  return request({
    url: "entry/listByPageSub",
    method: "GET",
    params,
  });
}

// /entry/getById
// 按id查看单个作品详情
export function getByIdSub(params) {
  return request({
    url: "entry/getByIdSub",
    method: "GET",
    params,
  });
}

///entry/markView
export function markSubView(params) {
  return request({
    url: "entry/markSubView",
    method: "POST",
    params,
  });
}
// 增加浏览次数
export function increaseSubView(params) {
  return request({
    url: "entry/increaseSubView",
    method: "POST",
    params,
  });
}